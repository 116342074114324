export default {

    init() {
        $('.posts-pagination').on('click', 'a', function(e){
            e.preventDefault();
            $(this).fadeOut();
            var pageNumber = $('#page-number').attr('href');
            var remaining = $('#page-number').data('remaining');
            var checkRemaining = remaining - 1;
            var checkPageNumber = parseFloat(pageNumber) + parseFloat(1);
            $('.posts-pagination a').data('remaining', checkRemaining);
            $('.posts-pagination a').attr('href', checkPageNumber);
            if(checkRemaining == 0){
              $('.posts-pagination').html(' ');
            }
            var data = {
              action : "load_more_news",
              page_number : pageNumber,
              remaining: remaining
            }
            $.ajax({
              type : 'post',
              url: "/wp-admin/admin-ajax.php",
              data : data,
              success : function(response){
                $('.news-posts-wrap').append(response);
                $('.posts-pagination a').fadeIn();
              }
            });
        });

        $('.press-pagination').on('click', 'a', function(e){
          e.preventDefault();
          var pageNumber = $('#press-page-number').attr('href');
          var remaining = $('#press-page-number').data('remaining');
          var checkRemaining = remaining - 1;
          var checkPageNumber = parseFloat(pageNumber) + parseFloat(1);
          $('.press-pagination a').data('remaining', checkRemaining);
          $('.press-pagination a').attr('href', checkPageNumber);
          if(checkRemaining == 0){
            $('.press-pagination').html(' ');
          }
          var data = {
            action : "load_more_press",
            page_number : pageNumber,
            remaining: remaining
          }
          $.ajax({
            type : 'post',
            url: "/wp-admin/admin-ajax.php",
            data : data,
            success : function(response){
              $('.press-posts').append(response);
            }
          });
      });
    }
}