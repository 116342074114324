import stiky from '../modules/sticky-header';
import burgerMenu from '../modules/burger-menu';
import gdprInfobar from '../modules/gdpr-infobar';
import debounce from '../utilities/debounce';
import blockFixedScrollingTabs from '../modules/fixedScrollingTabs';
import AOS from 'aos';
import resources from '../modules/resources';
import lotties from '../modules/lotties';
import pagination from '../modules/pagination';
import stickyAnchorNav from '../modules/stickyAnchorNav';
import accordion from '../modules/accordion';

export default {
    init(){
        //console.log('Nylon | Route: Common');
        stiky();
        burgerMenu();
        gdprInfobar();

        // fixed-scrolling-tabs block
        const scrollJackSlides = new blockFixedScrollingTabs();
        window.addEventListener('resize', debounce(() => scrollJackSlides.init(), 1000));
        scrollJackSlides.init();

        // init AOS
        var aosOffset = 10,
            aosDuration = 800;
        if ($('#mobile-breakpoint-tester').is(':hidden')) {
            aosOffset = 0;
            aosDuration = 750;
        }

        resources.init();

        pagination.init();

        AOS.init({
            duration: aosDuration,
            easing: 'ease-out-cubic',
            startEvent: 'DOMContentLoaded',
            offset: aosOffset
        });

        //****** smooth scrolling anchor links *******//
        // exclude fixed scrolling tab links
        $(function() {
            $('a[href*="#"]:not([href="#"]):not([data-slide-idx])').click(function() {
                if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
                    var target = $(this.hash);
                    target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                    
                    if (target.length) {
                        $('html,body').animate({
                            // offset to account for fixed nav
                            scrollTop: target.offset().top - 80
                        }, 1000);
                        return false;
                    }
                }
            });
        });

        // if hash exists on a page, scroll to element
        $(document).ready(function() { 
            var $elem = $('#_' + window.location.hash.replace('#', ''));
            if($elem.length) {
                $('html,body').animate({
                    // offset to account for fixed nav
                    scrollTop: $elem.offset().top - 80
                }, 1000);
            }
        });

        //****** modal logic ******//
        $('.modal-link').click(function(e) {
            e.preventDefault();
            e.stopPropagation();

            let thisMember = $(this).attr('data-modal'),
                $thisMemberModal = $('.modal[data-id="' + thisMember + '"]'),
                $thisMemberModalVideo = $thisMemberModal.find('iframe');

            // if there's a video in the modal
            if ($thisMemberModalVideo.length) {
                let thisMemberModalVideoUrl = $thisMemberModalVideo.attr('src');

                // if a youtube video
                if (thisMemberModalVideoUrl.indexOf('youtube') >= 0) {
                    thisMemberModalVideoUrl = thisMemberModalVideoUrl + '?autoplay=1';
                // if vimeo video
                } else if (thisMemberModalVideoUrl.indexOf('vimeo') >= 0) {
                    thisMemberModalVideoUrl = thisMemberModalVideoUrl + '&autoplay=1';
                }

                // replace src with autoplay src so video plays when modal opens
                $thisMemberModalVideo.attr('src', thisMemberModalVideoUrl);
            }

            $thisMemberModal.fadeIn('150');
        });
        $('button.close').click(function() {
            let $parentModal = $(this).parents('.modal'),
                $videoEl = $parentModal.find('iframe');

            // fade out modal
            $parentModal.fadeOut('150');

            // stop video
            if ($videoEl.length) {
                $videoEl.attr('src', $videoEl.attr('src').replace('autoplay=1', ''));
            }
        });
        $('body').click(function() {
            if ($('.modal').is(':visible')) {
                let $modal = $('.modal:visible'),
                    $videoEl = $modal.find('iframe');

                // fade out modal
                $modal.fadeOut('150');
                
                // stop video
                if ($videoEl.length) {
                    $videoEl.attr('src', $videoEl.attr('src').replace('autoplay=1', ''));
                }
            }
        });

        // if lottie animations exist on page
        if ($('.responsive-lottie').length) {
            lotties.init();
        }

        // if sticky anchor nav exists on page
        if ($('.building-block.sticky-anchor-nav').length) {
            stickyAnchorNav.init();
        }

        // if accordion exists on page
        if($('.building-block.content-faq').length) {
            accordion.init();
        }
            
    },
    finalize(){}
}