export default {
	init() {
		var $anchorNavSection = $('.building-block.sticky-anchor-nav'),
			$anchorNav = $('.sticky-anchor-nav-wrapper'),
			anchorNavHeight = $anchorNav.outerHeight(),
			headerNavHeight = $('#header').outerHeight();

		// set height on page so when nav goes sticky, the page height stays the same
		if (anchorNavHeight) {
			$anchorNavSection.css('height', anchorNavHeight);
		}

		// function for sticking the nav
		function fixedPositioner() {
			let stickyNavPos = $anchorNavSection.offset().top - 70;

			if ($(window).scrollTop() > stickyNavPos) {
		    	$anchorNavSection.addClass('sticky');
		    	$anchorNav.css({
		    		'top' : headerNavHeight
		    	});
		    } else {
		    	$anchorNavSection.removeClass('sticky');
		    	$anchorNav.css({
		    		'top' : 'unset',
		    	});
		    }
		}

		// on page load, check if nav should be stuck
		fixedPositioner();

		// set scrolling position init
		var lastScrollTop = 0;

		// stick nav when it gets to top of viewport
		$(window).on('scroll', function() {
			var windowTop = $(window).scrollTop(),
				windowWidth = window.innerWidth;

			// console.log(windowTop);
			
			// call function to make nav sticky
			fixedPositioner();

		    // all blocks with an id (b/c only a section with an id will have an anchor link)
		    if (windowWidth >= 768) {
		    	var $stickyTrigger = $('#sticky-anchor-nav'),
		    		$stickyNav = $('#sticky-anchor-nav'),
		    		scrollingUp = false;

		    	// figure out if user scrolling up or down
		    	if (windowTop < lastScrollTop) {
		    		scrollingUp = true;
		    	} else {
		    		scrollingUp = false;
		    	}

		    	// set new scroll position to compare (user scrolling up vs down)
		    	lastScrollTop = windowTop;

		    	// loop through sections
			    $('.building-block[id]').each(function(i, el) {
			    	let blockID = $(el).attr('id');

			    	if (blockID != 'sticky-anchor-nav') {
						// how far section is from top
			            var offsetTop = $(el).offset().top,
			                outerHeight = $(el).outerHeight(true),
			                wrapperTop = $stickyTrigger.offset().top;

			            // if distance to top of window is greater than the element's distance from top - 150px (offset)
			            // in other words, if element is 150px from top of window
			            // and distance to top of window is less than the height of the section (if inside the section)
			            if (windowTop > (offsetTop - 150) && windowTop < (offsetTop + outerHeight)) {
			              $stickyNav.find(".anchor-item.active").removeClass('active');
			              $stickyNav.find(".anchor-item[href='#" + blockID + "']").addClass('active');

			            // if outside of scroll area
			            } else if (windowTop < (wrapperTop - 150))  {
			              $stickyNav.find(".anchor-item.active").removeClass('active');            
			            }
			    	}
			    });

				// *************** //
				// scrolling up logic
				// *************** //

		        // get active anchor nav item and its info
		        let $activeSectionAnchor = $stickyNav.find('.anchor-item.active');

		        // if there is an active anchor nav item
		        if ($activeSectionAnchor.length) {
		        	let activeSectionID = $activeSectionAnchor.attr('href'),
		        		$activeSection = $('.building-block' + activeSectionID),
		        		activeSectionOffset = $activeSection.offset().top;

					// if scroll direction is up, and passes the active section
			        // shift active class to previous section
			        if (scrollingUp && windowTop < (activeSectionOffset - 150)) {
			        	let $activeSectionAnchorPrev = $activeSectionAnchor.prev('.anchor-item');

			        	$activeSectionAnchor.removeClass('active');
						$activeSectionAnchorPrev.addClass('active');
			        }	
		        }
			}
		});

		// when landing on page, check for hash
		if(window.location.hash) {
	    	let hash = window.location.hash.substring(1),
	    		$linkedSection = $('.building-block#' + hash);

	    	// scroll down to section with matching ID
	    	if ($linkedSection.length) {
                $('html,body').animate({
                    scrollTop: $linkedSection.offset().top
                }, 1000);
                return false;
	    	}
	    }
	}
}