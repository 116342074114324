import ScrollMagic from "scrollmagic";
import debounce from '../utilities/debounce';

class blockFixedScrollingTabs {
    constructor() {
        this.isSetup = false;
        this.sections = [];
    }

    init() {
        if (window.innerWidth > 767) {
            this.setup();

        } else {
            this.destroy();
        }
    }

    setup() {
        if (!this.isSetup && window.innerWidth > 767) {
            // Find all scrolljack sections
            document.querySelectorAll('section.fixed-scrolling-tabs')
                .forEach(function(section, sectionIndex) {
                    this.setupSection(section);
                }, this);

            this.isSetup = true;
        }
    }

    destroy() {
        this.sections.forEach(function(section, sectionIndex) {
            // Destroy Scrollmagic controller, resetting scene
            section.destroy(true);

            // Remove from array
            delete this.sections[sectionIndex];
        }, this);

        this.isSetup = false;
    }

    setupSection(section) {
        let controller = new ScrollMagic.Controller();

        // Set up elements
        let sectionDistanceToTop = window.pageYOffset + section.getBoundingClientRect().top
        window.addEventListener('resize', debounce(() => sectionDistanceToTop = window.pageYOffset + section.getBoundingClientRect().top, 1000));
        
        let slides = section.querySelectorAll('.tabs .tab');
        let menuItems = [];
        let slideCount = slides.length;
        let scenePercentageLength = 100 / slideCount
        let menu = section.querySelector('#tab-navigation');
        let totalDuration = 0;
        let currentSlideIdx = -1;
        let previousProgress = -1;
        let currentDirection = null;
        // let sceneDuration = (window.innerHeight * 0.75);
        let sceneDuration = (window.innerHeight * 0.55);
        let triggerHookSet = .25;

        if (section.classList.contains('has-titles')) {
            triggerHookSet = .12;
        }

        menu.addEventListener('click', function (event) {
            // Don't follow the link
            event.preventDefault();
            event.stopPropagation();

            // If the clicked element doesn't have the right selector, bail
            if (!event.target.matches('.tab-link a') || event.target.matches('.active')) return;

            let targetIdx = event.target.dataset.slideIdx;
            let newOffset = sectionDistanceToTop + (sceneDuration * Math.max(0, targetIdx));

            window.scrollTo({
                top: newOffset,
                behavior: 'smooth'
            });
        }, false);

        // Loop through slides
        slides.forEach((slide, slideIndex) => {
            menuItems.push(menu.querySelector('.' + slide.dataset.tab))

            totalDuration = totalDuration + sceneDuration;

            if (slideIndex > 0) {
                slide.classList.remove('active')
            }
        }, this);

        let scene = new ScrollMagic.Scene({
                triggerElement: section,
                triggerHook: triggerHookSet,
                duration: totalDuration
            })
            .setPin(section)
            .addTo(controller)

        let getSlide = (progress) => {
            if (progress >= 1) {
                return slideCount - 1;
            }

            let slidePercentageIndex = (Math.floor((progress * 100) / scenePercentageLength) * scenePercentageLength) % slideCount,
                realSlidePercentage = Math.floor(progress * 100),
                actualndex = Math.floor(slideCount * slidePercentageIndex),
                // percentage of progress / each slide's percentage of the total length
                newIndex = Math.floor(realSlidePercentage / scenePercentageLength);

            // if 2 slides 
            if (slideCount < 3) {
                // if < 50% through section
                if (realSlidePercentage < scenePercentageLength) {
                    return 0;
                // if > 50% through section
                } else {
                    return 1;
                }
            }
            // if even number of slides, then use slidePercentageIndex b/c it returns a whole number
            else if (slideCount % 2 == 0) {
                return newIndex;
            // if odd number of slides, use actualndex which converts the slidePercentageIndex to a whole number
            } else {
                return newIndex;
            }
        }

        let setSlide = (progress) => {
            let slideIdx = getSlide(progress);

            // console.log('slideIdx: ', slideIdx);
            // console.log('slides: ', slides);

            if ( slideIdx !== currentSlideIdx ) {
                let activeSlide = section.querySelector('.tab.active')
                if (activeSlide) {
                    activeSlide.classList.remove('active')
                }
                slides[slideIdx].classList.add('active')

                let activeMenuItem = menu.querySelector('.active')
                if (activeMenuItem) {
                    activeMenuItem.classList.remove('active')
                }
                menuItems[slideIdx].classList.add('active')

                currentSlideIdx = slideIdx
            }
        }

        let getSlideProgress = (progress) => {
            return Math.round(((progress * 100) % scenePercentageLength) * slideCount);
        }

        scene.on("progress", (event) => {
            setSlide(event.progress)

            previousProgress = event.progress;
        });

        this.sections.push(controller);
    }
}

export default blockFixedScrollingTabs
