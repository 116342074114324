const gdprInfobar = () => {
  if ( document.getElementById('gdpr-banner') ) {
    const gdprBanner = document.querySelector('#gdpr-banner');
    const rejectCta = gdprBanner.querySelector('.gdpr-infobar-allow-all');
    const allowAllCta = gdprBanner.querySelector('.gdpr-infobar-reject');

    if (window.localStorage.getItem('accept_gdpr')) {
      gdprBanner.classList.add('hidden');
    } else {
      gdprBanner.classList.remove('hidden');
    }

    rejectCta.addEventListener('click', () => {
      gdprBanner.classList.add('hidden');
      window.localStorage.setItem('accept_gdpr', true);
    });

    allowAllCta.addEventListener('click', () => {
      gdprBanner.classList.add('hidden');
      window.localStorage.setItem('accept_gdpr', true);
    });
  }
}

export default gdprInfobar;