const burgerMenu = () => {
  let burgerMenu = document.querySelector('.navbar-burger');
  let collapse = document.getElementById('navbarBasic');
  let wrapper = document.querySelector('body');
  let header = document.getElementById('header');
  burgerMenu.addEventListener('click',function(){
    this.classList.toggle("active");
    collapse.classList.toggle("active");
    header.classList.toggle("active");
    wrapper.classList.toggle("overflow-hidden");
  });
}

export default burgerMenu;