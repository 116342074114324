export default {
  init() {

   $('.filters').hide();

   $('.filter-list li a').on('click', function(e){
     e.preventDefault();
     $(this).toggleClass('active');
     $(this).parent().find('> .filters').fadeToggle();
   });
   
   $('.industries-filters a').on('click', function(e){
     e.preventDefault();
     var value = $(this).attr('href');
     var name = $(this).data('name');
     $('.industries-filters a').removeClass('active');
     $('.filter-list > li > a').removeClass('active');
     $(this).addClass('active');
     if(name == 'all'){
       $('.select-industry-name').html('Select Industry');
     } else {
      $('.select-industry-name').html(name);
     }
     $('.filters').hide();
   });

    $('.uses-filters a').on('click', function(e){
      e.preventDefault();
      var value = $(this).attr('href');
      var name = $(this).data('name');
      var skip = $(this).data('skip');
      var industry = $('.industries-filters a.active').attr('href');
      var data = {
        action : "filter_resources",
        post_type : "resource",
        skip : skip,
        taxonomy_industry : "resource_industry",
        taxonomy_use_case : "resource_use_case",
        resource_industry : industry,
        resource_use_case : value,
      }
      $.ajax({
        type : 'post',
        url: "/wp-admin/admin-ajax.php",
        data : data,
        success : function(response){
          //console.log(response);
          $('#ajax-posts').html(response);
        }
      })
      $('.uses-filters a').removeClass('active');
      $('.filter-list > li > a').removeClass('active');
      $(this).addClass('active');
      if(name == 'all'){
        $('.select-use-name').html('Select Use Case');
      } else {
        $('.select-use-name').html(name);
      }
      $('.filters').hide();
   });

   // Industries
   $('.industries-filters a').on('click', function(e){
     e.preventDefault();
     var value = $(this).attr('href');
     var name = $(this).data('name');
     var skip = $(this).data('skip');
     var use_case = $('.uses-filters a.active').attr('href');
     var data = {
       action : "filter_resources",
       post_type : "resource",
       skip : skip,
       taxonomy_industry : "resource_industry",
       taxonomy_use_case : "resource_use_case",
       resource_industry : value,
       resource_use_case : use_case,
     }
     $.ajax({
       type : 'post',
       url: "/wp-admin/admin-ajax.php",
       data : data,
       success : function(response){
         $('#ajax-posts').html(response);
       }
     })
     $('.industries-filters a').removeClass('active');
     $(this).addClass('active');
     if(name == 'all'){
       $('.select-industry-name').html('Select Use Case');
     } else {
       $('.select-industry-name').html(name);
     }
     $('.filters').hide();
  });
  

   	// ****************************************************************
   	// on single gated resources, check to see if an iframe exists
   	// if it does, wrap it in a responsive wrapper
   	// ****************************************************************

	// check on page load
	iframeChecker();

   	// start an interval timer to check for iframe
	var checkInterval = setInterval(iframeChecker, 1000);

	// on gated single resources, wrap iframe embed in a responsive container
	function wrapIframe() {
		let $iframe = $('.resource-form .custom-confirmation iframe');

	  	$iframe.wrapAll('<div class="responsive-embed"></div>');
	}

	// if iframe exists on the page and isn't wrapped in a responsive wrapper
	function iframeChecker() {
		if (($('.resource-form .custom-confirmation iframe').length) && !$('.resource-form .custom-confirmation .responsive-embed').length) {
			wrapIframe();
			clearInterval(checkInterval);
		}
	}

  }
};
