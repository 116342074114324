const sticky = () => {

  window.onscroll = function() { stickyHeader() };

  let header = document.getElementById('header');
  let sticky = header.offsetTop + 10;

  let stickyHeader = () => {
    if (window.pageYOffset > sticky) {
      header.classList.add('sticky');
    } else {
      header.classList.remove('sticky');
    }
  }
  stickyHeader();
}

export default sticky;