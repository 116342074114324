import "@lottiefiles/lottie-player";
import lottieWeb from "lottie-web";

export default {
  init() {
  		//console.log('lotties');

  		$('.responsive-lottie').each(function() {
  			var lottieSrc = $(this).attr('data-src'),
  				lottieName = $(this).attr('id'),
  				$lottieContainer = document.getElementById($(this).attr('id')),
  				$playerWrapper = $(this).parents('.content-side'),
  				animation,
            	lottieAnimation = {
					container: $lottieContainer,
					renderer: 'svg',
					loop: true,
					autoplay: false,
					path: lottieSrc,
					name: lottieName
				},
				isVisible = false;

			animation = lottie.loadAnimation(lottieAnimation);

			$(window).scroll(function(){
				if ($playerWrapper.hasClass('aos-animate')) {
					if (!isVisible) {
			        	animation.play(lottieName);
						isVisible = true;
					}
	    		} else {
	    			animation.stop(lottieName);
	    			isVisible = false;
	    		}
			});
  		});
    }
};