export default {
	init() {
		// show first faq item on page load
		$('.faqs-wrapper .faq-item:first-child').addClass('active');
		$('.faqs-wrapper .faq-item:first-child .faq-body').slideDown('200');

		// faq item toggles
		$('.faq-item .faq-title').on('click', function() {
			if ($(this).parents('.faq-item').hasClass('active')) {
				$(this).parents('.faq-item').removeClass('active');
				$(this).siblings('.faq-body').slideUp('200');
			} else {
				$(this).parents('.faq-item').addClass('active');
				$(this).siblings('.faq-body').slideDown('200');
			}
		});
	}
}